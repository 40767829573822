import './LandingPage.scss'
import LoginComponentUserAttributes from '../utils/UserAttributes'
import { ICommunity, IOrganization, IUserAttributes } from '../interfaces'
import { useNavigate } from '@tanstack/react-location'
import SalesPadUserModal from '../SalesPadGrid/SalesPadUserModal'
import { usePostPayload } from '../utils/PostPayloadContext'
import { signOut } from 'aws-amplify/auth'

interface IAcessProps {
  userAttributes: IUserAttributes
}

const BothAccess = (props: IAcessProps) => {
  const { userAttributes } = props
  const { shouldDisplayV3Logo, handleSignIn, handleV3SignIn } =
    LoginComponentUserAttributes()
  const navigate = useNavigate()
  const { openSalesPadModal } = usePostPayload()
  const handleSignInUser = (myplaceInfo: ICommunity[]) => {
    handleSignIn(myplaceInfo, userAttributes)
  }

  const handleSignOut = () => {
    signOut()
    localStorage.clear()
    navigate({ to: '/login', replace: true })
  }
  const handleV3SignInUser = () => {
    handleV3SignIn(userAttributes)
  }

  return (
    <div>
      <div className="welcome-block">
        <p className="welcome-txt">Welcome to MyPlace4Parts.</p>
      </div>
      <div>
        <div className="userContainer">
          <div className="user-block">
            <p className="userName">
              Welcome <b>{userAttributes?.displayUserName}</b>
            </p>
            {shouldDisplayV3Logo(userAttributes) && (
              <img
                className="userLogo"
                src={userAttributes.V3Access.v3Logo}
                onClick={handleV3SignInUser}
              />
            )}
            {userAttributes.V3Access?.acessType == '0' && (
              <img
                style={{ width: '10%' }}
                className="userLogo"
                src={userAttributes.myplaceInfo[0]?.logo}
                onClick={() => handleSignInUser(userAttributes.myplaceInfo)}
              />
            )}
            <input
              className="btn-signout"
              type="button"
              value="SIGN OUT"
              id="submit-button"
              onClick={() => handleSignOut()}
            />
          </div>
        </div>
        <div className="thanks-block">
          <p className="thanks-txt">
            Thank you for logging in Click the logo to access MyPlace4Parts.
          </p>
          <p className="thanks-txt2">
            You also have access to your supplier MyPlace Dashboard and Alliance
            Connect.
          </p>
        </div>
        <div className="bottom-logos-block">
          <div className="images-block">
            {userAttributes.orgsInfo.map((org: IOrganization | ICommunity) => {
              return (
                <img
                  src={org.logo}
                  className="image-properties"
                  onClick={() => handleSignInUser(userAttributes.orgsInfo)}
                />
              )
            })}
            {userAttributes.spaceInfo.length > 0 &&
              userAttributes.spaceInfo.map(
                (org: IOrganization | ICommunity) => {
                  return (
                    <img
                      src={org.logo}
                      className="image-properties"
                      onClick={() => handleSignInUser(userAttributes.orgsInfo)}
                    />
                  )
                }
              )}
          </div>
          {userAttributes.V3Access?.acessType == '2' && (
            <div>
              <p className="thanks-txt2">
                The legacy version of MyPlace will remain available for a
                limited period of time.
              </p>
              <p
                className="thanks-txt2 shop-navigation"
                onClick={() => handleSignInUser(userAttributes.myplaceInfo)}
              >
                {' '}
                Click here to access it
              </p>
            </div>
          )}
        </div>
      </div>
      <div>{openSalesPadModal && <SalesPadUserModal />}</div>
    </div>
  )
}

export default BothAccess
